
import { defineComponent } from "vue";

const monthNames = [
  "Tháng 1",
  "Tháng 2",
  "Tháng 3",
  "Tháng 4",
  "Tháng 5",
  "Tháng 6",
  "Tháng 7",
  "Tháng 8",
  "Tháng 9",
  "Tháng 10",
  "Tháng 11",
  "Tháng 12",
];

export default defineComponent({
  name: "Year",
  mounted() {},
  computed: {
    nextYear(): number | undefined {
      if (this.year === undefined) {
        return undefined;
      }
      return this.year + 1;
    },
    nextYearPath(): string | undefined {
      if (this.year === undefined) {
        return undefined;
      }
      return `/year/${this.nextYear}`;
    },
    lastYear(): number | undefined {
      if (this.year === undefined) {
        return undefined;
      }
      return this.year - 1;
    },
    lastYearPath(): string | undefined {
      if (this.year === undefined) {
        return undefined;
      }
      return `/year/${this.lastYear}`;
    },
    year() {
      const yearInput = this.$route.params.year as string;
      if (!yearInput) {
        return undefined;
      }
      return Number(yearInput);
    },
    yearLbl(): string | undefined {
      const yearInput = this.$route.params.year as string;
      if (!yearInput) {
        return undefined;
      }
      return "Năm " + yearInput;
    },
    months(): Number[] {
      const month = [];
      for (let index = 0; index < 12; index++) {
        month.push(index);
      }
      return month;
    },
    monthNames(): string[] {
      return monthNames;
    },
  },
});
